<template>
  <v-card v-if="this.batchItems">
    <v-row>
      <v-col cols="12" md="1" class="px-6 pb-8">
        <v-btn class="aBtn--primaryInv mr-2" stacked large @click="printAll()" :loading="isPrinting">
          <div class="ma-1 pa-1">
            Tisk
          </div>
          <div v-if="selected.length">
            <v-badge :content="selected.length">
              <v-icon>mdi-printer</v-icon>
            </v-badge>
          </div>
          <div v-else>
            <v-icon>mdi-printer</v-icon>
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
        v-model="selected"
        :headers="headers"
        :items="batchItems"
        :loading="loading"
        class="vDataTable--dense elevation-0"
        :hide-default-footer="true"
        disable-pagination
        show-expand
        :show-select="true"
        :expanded.sync="expanded"
    >
      <template #no-data>
        Žádná data...
      </template>
      <template #loading>
        Načítání dat...
      </template>


      <template v-slot:expanded-item="{headers,  item }">
        <td :colspan="headers.length">
          <div class="vDataTable__expanded app__grid app__grid--halved gx-8">
            <div>
              <div class="vDataTable--specsWrapper my-4">
                <div class="desc font-weight-bold">Typ / váha</div>
              </div>
              <v-select
                  label="Typ šperku"
                  outlined
                  v-model="item.type_jewel"
                  :items="jewelTypes"
                  :item-text="(value) => value.description"
                  :item-value="(value) => value.id"
                  :menu-props="{ offsetY: true }"
              />
              <div class="vDataTable__btnsWrapper vDataTable__btnsWrapper--field my-4">
                <v-text-field
                    v-model="item.weight"
                    label="Váha "
                    :default="0"
                    step="0.01"
                    suffix="g"
                    outlined
                    type="number"
                    @input="handleWeightChange(item)"
                />
                <v-btn large class="primary" @click="getWeight(item)" :disabled="!isSerialPortApiAvailable()">
                  Zvážit
                </v-btn>
              </div>
              <v-divider class="mt-6 mb-1"/>
              <v-expansion-panels
                  v-model="showSellPrice"
                  flat
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="vDataTable--specsWrapper">
                      <div class="desc font-weight-bold">Prodejní cena</div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-alert
                        type="success"
                        color="#bb9765"
                        text
                        prominent
                    >
                      <h4 class="mb-4">
                        Prodejní cena:
                      </h4>
                      <div v-for="(prices, i) in sellPrices" :key="i" class="pricesGrid">
                        <v-divider></v-divider>
                        <div v-for="price in prices" :key="price.id">
                          <div class="font-weight-bold"> {{ price.price_type.description }}</div>
                          <div>{{ price.price_limit.description }}</div>
                          <div class="text-right">{{ formattedPrice(price.price) }} Kč</div>
                        </div>
                      </div>
                    </v-alert>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-radio-group
                  v-model="item.sell_price_action"
                  row
                  class="mt-0 pt-0 mb-4"
                  @change="handleSellPriceChange(item)"
              >
                <v-radio
                    :label="'automatická ('+ item.sell_price_auto +' Kč/g)'"
                    value="auto"
                />
                <v-radio
                    label="ruční"
                    value="manual"
                />
              </v-radio-group>

              <v-alert
                  v-if="!item.sell_price_auto && sellPriceLoaded"
                  type="error"
                  color="#eb5757"
                  text
                  prominent
                  class="mt-4"
              >
                <div>
                  <h4 class="mb-4">
                    Pozor - Není vyplněnen "Attribut - Jakost"
                  </h4>
                </div>
              </v-alert>

              <v-text-field
                  v-model="item.sell_price"
                  label="Cena "
                  outlined type="number"
                  step="0.01"
                  :default="0"
                  :disabled="item.sell_price_action === 'auto'"
                  suffix="Kč"
              />
              <v-divider class="mt-6 mb-1"/>
              <v-expansion-panels
                  v-model="shoBuyPrice"
                  flat
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="vDataTable--specsWrapper">
                      <div class="desc font-weight-bold">Nákupní cena</div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-alert
                        type="success"
                        color="#bb9765"
                        text
                        prominent
                    >

                      <h4 class="mb-4" v-if="buyPrices.length">
                        Nákupní cena: {{ buyPrices[0].year }} - {{ buyPrices[0].month }}
                      </h4>
                      <div class="pricesGrid">
                        <div v-for="price in buyPrices" :key="price.id">
                          <div class="font-weight-bold">{{ price.purity }}</div>
                          <div class="text-right">{{ formattedPrice(price.price) }} Kč</div>
                          <div/>
                        </div>
                      </div>
                    </v-alert>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-radio-group
                  v-model="item.buy_price_action"
                  row
                  class="mt-0 pt-0 mb-4"
                  @change="handleBuyPriceChange(item)"
              >
                <v-radio
                    :label="'automatická ('+ item.buy_price_auto +' Kč/g)'"
                    value="auto"
                />
                <v-radio
                    label="ruční"
                    value="manual"
                />
              </v-radio-group>
              <v-text-field
                  label="Cena "
                  v-model="item.buy_price"
                  outlined type="number"
                  :disabled="item.buy_price_action === 'auto'"
                  step="0.01"
                  suffix="Kč"
              />
              <v-alert
                  v-if="!item.buy_price_auto && buyPriceLoaded"
                  type="error"
                  color="#eb5757"
                  text
                  prominent
                  class="mt-4"
              >
                <div class="pl-2">
                  <h4 class="mb-4">
                    Pozor - Nexistuje automaticka nakupní cena pro
                    <span v-if="batchCreated"> {{ batchCreated.getFullYear() }} -  {{
                        batchCreated.getMonth() + 1
                      }}</span>
                  </h4>
                  <ul>
                    <li>{{ item.batch.commodity_purity?.full_description }}</li>
                  </ul>
                </div>
              </v-alert>
            </div>
            <div>
              <div
                  v-if="item.batch_item_attributes.length"
                  class="vDataTable--specsWrapper"
              >
                <div
                    v-if="!$vuetify.breakpoint.xs"
                    class="desc font-weight-bold mb-2"
                >
                  Atributy
                </div>
                <v-simple-table dense>
                  <template #default>
                    <tbody>
                    <tr v-for="(attribute, i) in item.batch_item_attributes" :key="i">
                      <td>{{ attribute.attribute_name_description }}</td>
                      <td>
                        <div class="d-flex align-center">
                          <div>{{ attribute.attribute_choice_value }}</div>
                          <v-btn
                              class="aBtn--redTone1 ml-auto"
                              style="padding: 1px 0 0 1px;"
                              x-small
                              icon
                              @click="deleteAttribute(attribute)"
                          >
                            <v-icon x-small>
                              mdi-close
                            </v-icon>
                          </v-btn>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-simple-table dense>
                  <template #default>
                    <tbody>
                    <tr v-for="(attribute, i) in getMissingAttributes(item.batch_item_attributes)" :key="i"
                        style="background:#eb5757;color:#f8f6f3">
                      <td>{{ attribute.name }}</td>
                      <td>
                        <div class="d-flex align-center">
                          <div> Nevyplněno</div>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div
                  v-if="item.batch_item_categories.length"
                  class="vDataTable--specsWrapper"
              >
                <div
                    v-if="!$vuetify.breakpoint.xs"
                    class="desc font-weight-bold mb-2"
                >
                  Obory
                </div>
                <v-simple-table dense>
                  <template #default>
                    <tbody>
                    <tr v-for="(category, i) in item.batch_item_categories" :key="i">
                      <td>{{ category.group_name }}</td>
                      <td>{{ category.name }}</td>
                      <td>
                        <div class="d-flex align-center">
                          <div>{{ category.code }}</div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-center">
                          <v-btn
                              class="aBtn--redTone1 ml-auto"
                              style="padding: 1px 0 0 1px;"
                              x-small
                              icon
                              @click="deleteCategory(category)"
                          >
                            <v-icon x-small>
                              mdi-close
                            </v-icon>
                          </v-btn>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-simple-table dense>
                  <template #default>
                    <tbody>
                    <tr v-for="(category, i) in getMissingCategories(item.batch_item_categories)" :key="i"
                        style="background:#eb5757;color:#f8f6f3">
                      <td>{{ category.name }}</td>
                      <td>Nevyplněno</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>


              </div>
            </div>
          </div>
        </td>
      </template>

      <template #[`item.index`]="{ index }">

        <div class="font-weight-bold">{{ index + 1 }}</div>
      </template>

      <template #[`item.categories`]="{item}">
        <categories-popup :id="item.id" @save="reloadAttributes(item)"></categories-popup>
      </template>

      <template #[`item.attributes`]="{item}">
        <attributes-popup :init-attributes="item.batch_item_attributes" :id="item.id"
                          @save="reloadAttributes(item)"></attributes-popup>
      </template>

      <template #[`item.image`]="{item}">
        <DialogImagePreview v-if="item.image" :image="item.image">
          <template #activator="{on}">
            <v-icon v-on="on"> mdi-cloud-download</v-icon>
          </template>
        </DialogImagePreview>
      </template>

      <template #[`item.is_exported`]="{item}">
        <div
            v-if="item.is_exported"
            class="gLabel gLabel--success"
        >
          <v-icon small>$circle-check</v-icon>
          Ano
        </div>
        <div
            v-else
            class="gLabel gLabel--error"
        >
          <v-icon small>$circle-exclamation</v-icon>
          Ne
        </div>
      </template>


      <template #[`item.boss_voucher_send`]="{item}">
        <div
            v-if="item.is_exported"
            class="gLabel gLabel--success"
        >
          <v-icon small>$circle-check</v-icon>
          Ano
        </div>
        <div
            v-else
            class="gLabel gLabel--error"
        >
          <v-icon small>$circle-exclamation</v-icon>
          Ne
        </div>
      </template>

      <template #[`item.print`]="{item}">
        <v-btn
            :disabled="!item.code"
            class="aBtn--primaryInv mr-2"
            small
            :loading="item.print"
            @click="printItem(item)"
        >
          Tisk
        </v-btn>
      </template>
    </v-data-table>
    <v-container>
      <div class="d-flex">
        <v-btn
            :disabled="!selected.length"
            :loading="sending"
            class="ml-auto"
            color="primary"
            @click="sendToBoss()"
        >
          Založit Produkt
        </v-btn>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import {
  deleteBatchItemAttribute, deleteCategory,
  exportToBoss, fetchArticleCategoryGroup,
  fetchBatchItemFinishedList, fetchJewelTypeList, printLabel
} from "@/components/batch/api";
import {getSerialPortData, isSerialPortApiAvailable} from "@/utils/serialport";
import {fetchAttrNames, fetchBuyPrices, fetchSellPrices} from "@/components/zlaton/api";
import AttributesPopup from "@/components/batch/AttributesPopup.vue";
import CategoriesPopup from "@/components/batch/CategoriesPopup.vue";
import {formattedPrice} from "@/utils/formatters";
import {ACTION_TYPES as AT} from "@/store/action-types";
import DialogImagePreview from "@/components/batch/DialogImagePreview.vue";

export default {
  name: 'BatchSendToBoss',
  components: {DialogImagePreview, CategoriesPopup, AttributesPopup},
  props: {
    id: {required: true, type: String},
  },
  watch: {
    batchItems: {
      handler(newVal) {
        this.refreshPrices()
        //React to change on selected item
        this.selected = newVal.filter(i => this.selected.findIndex(item => item.id === i.id) > -1)
      },
      deep: true
    },
  },
  computed: {
    batchCreated() {
      if (this.batch) {
        return new Date(this.batch.x_created);
      }
      return null
    },
    requiredAttrNames() {
      return this.attrNames.filter((i) => i.required_web === true)
    },
    requiredCategoryGroups() {
      return this.categoryGroups.filter((i) => i.required_web === true)
    },
    hasMissingAttr() {
      if (!this.batchItems) {
        return true
      }
      return this.batchItems.some((i) => this.getMissingAttributes(i.batch_item_attributes).length > 0);
    },
    hasMissingCategory() {
      if (!this.batchItems) {
        return true
      }
      return this.batchItems.some((i) => this.getMissingCategories(i.batch_item_categories).length > 0);
    }
  },
  data() {
    return {
      selected: [],
      expanded: [],
      loading: false,
      buyPriceLoaded: false,
      sellPriceLoaded: false,
      sending: false,
      headers: [
        {text: '#', value: 'index'},
        {text: 'Kód', align: 'start', value: 'code', width: 120},
        {text: '', value: 'sortButton', sortable: false},
        {text: 'Ryzost', value: "batch.commodity_purity.full_description", sortable: false, width: '25%'},
        {text: 'Popis', value: 'description', sortable: false, width: '35%'},
        {text: 'Obrázek', value: 'image', width: 210},
        {text: 'Odesláno', value: 'is_exported', width: 108},
        {text: 'Doklad', value: 'boss_voucher_send', width: 108},
        {text: 'Obory', value: 'categories', sortable: false},
        {text: 'Atributy', align: 'start', value: 'attributes', sortable: false},
        {text: '', value: 'print'},
      ],
      batchItems: [],
      jewelTypes: [],
      jewelType: null,
      selectedItem: null,
      attrNames: [],
      categoryGroups: [],
      buyPrices: [],
      sellPrices: [],
      shoBuyPrice: false,
      showSellPrice: false,
      isPrinting: false
    }
  },
  async mounted() {
    await this.getData()
  },
  methods: {
    async printAll() {
      this.isPrinting = true;
      for (const item of this.selected) {
        await this.printItem(item);
      }
      this.isPrinting = false
    },
    isBetween(value, min, max) {
      if (min && max) {
        return value >= min && value <= max;
      } else if (min) {
        return value >= min;
      } else if (max) {
        return value <= max;
      }
      return false;
    },
    setAutoSellPrice(item) {
      let limitPrice = 0
      const weight = item.weight
      const category = this.get_attr_identifier_from_itme(item, "cfArticleQuality")
      const quality = this.get_attr_value_form_item(item, "cfGoldQuality")
      let limits;
      if ((quality === "750/1000" || quality === "750/1000 (18kt)") && category === "02") {
        limits = this.sellPrices["750/1000"] ?? undefined
      } else {
        limits = this.sellPrices[category] ?? undefined
      }
      if (limits) {
        for (const i of limits) {
          if (this.isBetween(Number(weight), i.price_limit.weight_from, i.price_limit.weight_to)) {
            limitPrice = i.price
            break;
          }
        }
      }
      item.sell_price_auto = limitPrice
    },
    setAutoBuyPrice(item) {
      const bPrice = this.buyPrices[item.batch.commodity_purity?.purity]
      item.buy_price_auto = bPrice ? bPrice.price : 0
    },
    get_attr_value_form_item(item, attr_identifier) {
      let match = item.batch_item_attributes.filter((i) => i.attribute_name_identifier === attr_identifier)
      return match.length ? match[0].attribute_choice_value : ""
    },
    get_attr_identifier_from_itme(item, attr_identifier) {
      let match = item.batch_item_attributes.filter((i) => i.attribute_name_identifier === attr_identifier)
      return match.length ? match[0].attribute_choice_identifier : ""
    },
    formattedPrice,
    async printItem(item) {
      item.print = true
      let purity = this.get_attr_value_form_item(item, "cfGoldQuality")
      let size = this.get_attr_value_form_item(item, "cfSize")
      let stoneWeight = this.get_attr_value_form_item(item, "cfWeightStone")
      let stoneSize = this.get_attr_value_form_item(item, "cfStoneSize")
      let stoneColor = this.get_attr_value_form_item(item, "cfStoneColor")
      let stoneClear = this.get_attr_value_form_item(item, "cfStoneClear")
      let stone = `${stoneWeight || stoneSize} ${stoneClear} ${stoneColor}`
      let secondaryStoneWeight = this.get_attr_value_form_item(item, "cfWeightSecondaryStone")
      let secondaryStoneSize = this.get_attr_value_form_item(item, "cfSecondaryStoneSize")
      let secondaryStoneColor = this.get_attr_value_form_item(item, "cfSecondaryStoneColor")
      let secondaryStoneClear = this.get_attr_value_form_item(item, "cfSecondaryStoneClear")
      let secondary_stone = `${secondaryStoneWeight || secondaryStoneSize} ${secondaryStoneClear} ${secondaryStoneColor}`;
      await printLabel(item.code, item.weight, item.sell_price, size, purity, stone, secondary_stone).then(() => {
        item.print = false;
      })
    },
    handleWeightChange(item) {
      item.buy_price = this.getBuyPrice(item);
      item.sell_price = this.getSellPrice(item);
    },
    handleSellPriceChange(item) {
      item.sell_price = this.getSellPrice(item);
    },
    handleBuyPriceChange(item) {
      item.buy_price = this.getBuyPrice(item);
    },
    getBuyPrice(item) {
      this.setAutoBuyPrice(item)
      if (item.buy_price && item.buy_price_action === "manual") {
        return item.buy_price
      }
      return Math.ceil(item.weight * item.buy_price_auto)
    },
    getSellPrice(item) {
      this.setAutoSellPrice(item)
      if (item.sell_price && item.sell_price_action === "manual") {
        return item.sell_price
      }
      return Math.ceil(item.weight * item.sell_price_auto)
    },
    reloadAttributes() {
      this.getItems();
    },
    isSerialPortApiAvailable,
    getWeight(item) {
      //TODO implement serial port weight  https://developer.mozilla.org/en-US/docs/Web/API/SerialPort
      getSerialPortData().then(value => {
        item.weight = value
      })
    },
    getMissingAttributes(attributes) {
      const current = attributes.map(i => i.attribute_name_identifier)
      return this.requiredAttrNames.filter(item => !current.includes(item.boss_identifier));
    },
    getMissingCategories(categories) {
      const current = categories.map(i => i.group_index)
      return this.requiredCategoryGroups.filter(item => !current.includes(item.index2));
    },
    async deleteAttribute(attribute) {
      await deleteBatchItemAttribute(attribute.id).then(_ => {
        this.getItems();
      })
    },
    async deleteCategory(category) {
      await deleteCategory(category.id).then(_ => {
        this.getItems();
      })
    },
    async sendToBoss() {
      this.sending = true
      await exportToBoss(this.id, this.selected).then(_ => {
        this.$store.dispatch(AT.displayMessage, {
          errorMessage: 'Produkty byly úspěšně založeny.',
          many: false,
          isStringMessage: true
        })
        this.getData()
      }).finally(() => {
        this.sending = false
        this.$store.dispatch(AT.displayMessage, {
          errorMessage: 'Produkty byly úspěšně vytvořen',
          many: false,
          isStringMessage: true
        })
      }).catch(_ => {
            this.$store.dispatch(AT.displayErrors, {
              errorMessage: 'Při zakládání produktů došlo k chybě.',
              many: false,
              isStringMessage: true
            })
          }
      )
    },
    async getJewelList() {
      fetchJewelTypeList().then((response) => {
        this.jewelTypes = response.data
      })
    },
    async getItems() {
      fetchBatchItemFinishedList(this.id).then(response => {
        this.batch = response.data.length ? response.data[0].batch : null
        this.fetchSellPrices()
        this.fetchBuyPrices()
        this.batchItems = response.data.map((bi) => (
            {
              ...bi,
              buy_price: 0,
              buy_price_auto: 0,
              sell_price: 0,
              sell_price_auto: 0,
              sell_price_action: bi.sell_price_action.length ? bi.sell_price_action : "auto",
              buy_price_action: bi.buy_price_action.length ? bi.buy_price_action : "auto",
              print: false
            }
        ))
        this.expanded = response.data
      })
    },
    async fetchSellPrices() {
      fetchSellPrices().then(response => {
        this.sellPrices = Object.groupBy(response.data, item => item.price_type.identifier)
        this.refreshPrices()
        this.sellPriceLoaded = true;
      })
    },
    refreshPrices() {
      this.batchItems.forEach((i) => {
        i.buy_price = this.getBuyPrice(i)
        i.sell_price = this.getSellPrice(i)
      })
    },
    async fetchBuyPrices() {
      if (this.batchCreated) {
        fetchBuyPrices(this.batchCreated.getFullYear(), this.batchCreated.getMonth() + 1).then(response => {
          const transformedData = {};
          response.data.forEach(item => {
            transformedData[item.purity] = item;
          });
          this.buyPrices = transformedData
          this.refreshPrices()
          this.buyPriceLoaded = true;
        })
      }
    },
    async getCategoryGroups() {
      fetchArticleCategoryGroup().then(response => {
        this.categoryGroups = response.data
      })
    },
    async getData() {
      this.loading = true
      await this.getJewelList()
      await this.getItems()
      await this.getAttrNames()
      await this.getCategoryGroups()
      this.loading = false
    },
    async getAttrNames() {
      fetchAttrNames().then(response => {
        this.attrNames = response.data
      })
    },
  }
}

</script>

<style scoped lang="scss">
.vDataTable__expanded {
  .v-expansion-panel-header {
    padding: 0;
  }

  .v-expansion-panel--active {
    > .v-expansion-panel-header {
      min-height: 48px;
    }
  }

  :deep(.v-expansion-panel-content__wrap) {
    padding: 0;

    .v-alert--prominent .v-alert__icon {
      align-self: flex-start;
    }
  }
}

:deep(.v-alert__content:has(.pricesGrid)) {
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 0 32px;
  max-width: max-content;

  .pricesGrid {
    &,
    > div {
      display: contents;
    }
  }

  h4,
  .v-divider {
    grid-column: span 3;
  }
}
</style>