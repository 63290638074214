import getEnv from "@/utils/env";

export const URLS = {
    shopsList: () => 'api/buyout/shops/list',
    shopsListAllowedOnly: () => 'api/buyout/shops/list/allowed-only',
    cashRegistersAllowedOnly: () => 'api/buyout/cash-registers/list/allowed-only',
    userUpdate: (username: string) => `api/core/user-shop/update/${username}`,
    token: () => 'api/token',
    userCredentials: (username: string) => `api/core/user-credentials/${username}`,
    buyoutList: () => 'api/buyout/list/',
    buyoutListExcel: () => 'api/buyout/list/excel',
    createNewBuyout: () => 'api/buyout/create',
    deleteBuyout: (buyoutId: string) => `api/buyout/delete/${buyoutId}`,
    exportBuyoutsToBoss: (buyoutIds: []) => `api/buyout/export-boss/${buyoutIds}`,
    commodityTypesList: () => 'api/buyout/commodities/list',
    genderTypesList: () => 'api/buyout/genders/list',
    stateTypeList: () => 'api/buyout/states/list',
    stateCZ: () => 'api/buyout/state/cz',
    buyoutItemsList: (buyoutId: string) => `api/buyout/buyout-items/${buyoutId}`,
    buyoutItemUpdate: (commodityId: string) => `api/buyout/buyout-item/update/${commodityId}`,
    buyoutItemCreate: () => `api/buyout/buyout-item/create`,
    buyoutItemDelete: (commodityId: string) => `api/buyout/buyout-item/delete/${commodityId}`,
    buyoutCustomer: (buyoutId: string) => `api/buyout/customer/${buyoutId}`,
    buyoutStatusUpdate: (buyoutId: string) => `api/buyout/type-status/${buyoutId}`,
    existingCustomer: (nationalIdNumber: string) => `api/buyout/customer/existing/${nationalIdNumber}`,
    buyoutNote: (buyoutId: string) => `api/buyout/note/${buyoutId}`,
    buyoutTotalPrice: (buyoutId: string) => `api/buyout/total-price/${buyoutId}`,
    buyoutBasicInfo: (buyoutId: string) => `api/buyout/basic-info/${buyoutId}`,
    commodityPurities: () => `api/buyout/commodity-purities/list`,
    finishBuyout: (buyoutId: string) => `api/buyout/finish/${buyoutId}`,
    visibleStatuses: () => 'api/buyout/visible-statuses',
    contractPreview: (buyoutId: string, update: boolean = false) => update ? `api/buyout/contract/pdf/${buyoutId}?update=1` : `api/buyout/contract/pdf/${buyoutId}`,
    passwordChange: () => 'api/core/password-change',
    adminLink: () => 'admin',
    gBuyoutItemsList: (buyoutId: string) => `api/goldsmith/buyout-items/list/${buyoutId}`,
    createGoldsmithItem: (buyoutId: string, buyoutItemId: string) => `api/goldsmith/goldsmith-item/create/${buyoutId}/${buyoutItemId}`,
    goldsmithItemsList: (buyoutId: string) => `api/goldsmith/goldsmith-item/list/${buyoutId}`,
    goldsmithItemDetail: (goldsmithItemId: string) => `api/goldsmith/goldsmith-item/detail/${goldsmithItemId}`,
    goldsmithItemDestroy: (goldsmithItemId: string) => `api/goldsmith/goldsmith-item/destroy/${goldsmithItemId}`,
    goldsmithItemUpdate: (goldsmithItemId: string) => `api/goldsmith/goldsmith-item/update/${goldsmithItemId}`,
    goldsmithItemMaxWeight: (goldsmithItemId: string) => `api/goldsmith/goldsmith-item/max-weight/${goldsmithItemId}`,
    goldsmithItemUploadImage: (goldsmithItemId: string) => `api/goldsmith/goldsmith-item/upload-image/${goldsmithItemId}`,
    typeBranchesOfGoodsList: () => 'api/goldsmith/type-branch-of-goods/list',
    subtypeBranchesOfGoodsList: () => 'api/goldsmith/subtype-branch-of-goods/list',

    //Buyout
    batchBuyoutList: () => 'api/batch/buyout/list',
    updateBuyout: (buyoutId: string) => `api/batch/buyout/update/${buyoutId}`,
    batchBuyoutItem: (buyoutItemId: string) => `api/batch/buyout/item/${buyoutItemId}`,
    batchBuyoutItemList: (buyoutId: string) => `api/batch/buyout/item/list/${buyoutId}`,


    //BatchItem Buyout
    batchBuyoutBatchItemCreate: (itemId: string | null) => `api/batch/batchbuyoutitem/create/${itemId}`,
    batchBuyoutBatchItemDelete: (itemId: string) => `api/batch/batchbuyoutitem/delete/${itemId}`,
    batchBuyoutBatchItemList: (itemId: string) => `api/batch/batchbuyoutitem/list/${itemId}`,

    //BatchItem Buyout jewels
    batchBuyoutBatchItemJewelCreate: (itemId: string | null) => `api/batch/batchbuyoutitem/jewel/create/${itemId}`,
    batchBuyoutBatchItemJewelDelete: (itemId: string) => `api/batch/batchbuyoutitem/jewel/delete/${itemId}`,
    batchBuyoutBatchItemJewelList: (itemId: string) => `api/batch/batchbuyoutitem/jewel/list/${itemId}`,

    //BatchItem
    batchItem: (itemId: string) => `api/batch/item/${itemId}`,
    batchItemCreate: (itemId: string | null) => `api/batch/item/create/${itemId}`,
    batchItemDelete: (itemId: string) => `api/batch/item/delete/${itemId}`,
    batchItemUpdate: (itemId: string) => `api/batch/item/update/${itemId}`,
    batchItemList: (itemId: string, state: string) => `api/batch/item/list/${itemId}/${state}`,

    //Batch
    createBatch: () => `api/batch/create`,
    batchList: () => `api/batch/list`,
    batchMove: () => `api/batch/move`,
    fetchBatch: (id: string) => `api/batch/${id}`,
    updateBatch: (id: string) => `api/batch/${id}`,
    fetchBatchBuyoutList: (batchId: string) => `api/batch/buyout/list/${batchId}`,


    batchTypesList: () => 'api/batch/type/list',
    jewelTypesList: () => 'api/batch/jewel/list',
    batchStates:() => `api/batch/state/list`,
    batchStateList: (batchId: string) => `api/batch/state/list/${batchId}`,


    batchItemAssigned: (batchId: string) => `api/batch/item/assigned/list/${batchId}`,
    batchUploadImage: (batchItemId: string) => `api/batch/upload/${batchItemId}`,
    batchItemReviewList: (batchId: string, state: string) => `api/batch/item/review/${state}/${batchId}`,
    batchItemsStateUpdate: (state: string) => `api/batch/items/state/update/${state}`,
    batchPrintLabel: () => `api/batch/print/label`,
    batchExport: (batchId: string) => `api/batch/export/${batchId}`,
    saveBatchItemAttributes: (batchItemId: string) => `api/batch/item/attributes/save/${batchItemId}`,
    fetchBatchItemAttributes: (batchItemId: string) => `api/batch/item/attributes/list/${batchItemId}`,
    deleteBatchItemAttribute: (attrId: string) => `api/batch/item/attribute/delete/${attrId}`,
    saveBatchItemCategories: (batchItemId: string) => `api/batch/item/categories/save/${batchItemId}`,
    deleteBatchItemCategory: (categoryId: string) => `api/batch/item/categories/delete/${categoryId}`,

    //Zlaton
    fetchAttrNames: () => 'api/zlaton/attr/name/list',
    fetchSellPrices: () => 'api/zlaton/prices/sell/list',
    fetchBuyPrices: (year: number, month: number) => `api/zlaton/prices/buy/list/${year}/${month}`,

    //Boss
    fetchArticleCategoryGroup: () => `api/boss/categorygroup/list`,
}

export const WEBSOCKETS = {
    finishBuyout: () => `${getEnv('VUE_APP_WS_URL')}finish-buyout/`
}