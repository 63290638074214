<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="440"
  >
    <template v-slot:activator="{on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>
    <v-card>
      <v-container>
        <v-card-title class="px-2 pt-0 pb-5">
          Poznámka
        </v-card-title>
        <v-card-text class="px-2 pb-0">
          <v-row>
            <v-col
                cols="12"
                md="12"
                class="d-flex ga-4"
            >
              <v-text-field
                  outlined
                  label="Hmotnost"
                  :rules="[rules.required, rules.isValidDecimal]"
                  validate-on-blur
                  v-model="weight"
                  :min="0"
                  type="number"
              >
              </v-text-field>
              <v-btn large class="primary" @click="getSerialPortData()" :disabled="!isSerialPortApiAvailable()">
                Zvážit
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                md="12"
                class="d-flex ga-4"
            >
              <v-textarea label="Poznámka" v-model="note" outlined></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex flex-wrap mt-4">
          <v-btn class="aBtn--primaryInv flex-grow-1" @click="dialog=false">Zrušit</v-btn>
          <v-btn class="primary flex-grow-1" @click="confirmAction" :loading="saving">Uložit</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

import rules from "@/utils/rules";
import {updateBatch} from "@/components/batch/api";
import {ACTION_TYPES as AT} from "@/store/action-types";
import {getSerialPortData, isSerialPortApiAvailable} from "@/utils/serialport";

export default {
  name: 'DialogBatchNote',
  computed: {
    rules() {
      return rules
    },
  },
  props: {
    batch: {
      required: false, type: Object, default: () => {
      },
    },
  },
  watch: {
    dialog: {
      handler() {
        this.selectedBatchItems = this.batchItems
      }
    },
  },
  data() {
    return {
      dialog: false,
      saving: false,
      note: this.batch.note,
      weight: this.batch.weight
    }
  },
  methods: {
    isSerialPortApiAvailable,
    getSerialPortData,
    reset() {
    },
    async confirmAction() {
      this.saving = true
      await updateBatch(this.batch.id, {note: this.note , weight: this.weight})
      this.$emit("update")
      this.$store.dispatch(AT.displayMessage, {
        errorMessage: 'Várka byla úspěšně uložena',
        many: false,
        isStringMessage: true
      })
      this.dialog = false
      this.saving = false
      this.reset()
    },
  }

}

</script>