<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="440"
  >
    <template v-slot:activator="{on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>
    <v-card>
      <v-container>
        <v-card-title class="px-2 pt-0 pb-5">
          Přesunout várky
        </v-card-title>
        <v-card-text class="px-2 pb-0">

          <v-alert
              v-if="this.batch"
              type="error"
              color="#eb5757"
              text
              prominent
              class="mt-4"
          >
            <div>
              <h4 class="mb-4">
                Pozor - lze vyhledat várky s ryzostí. '{{ this.batch.commodity_purity?.purity }}'
              </h4>
            </div>
          </v-alert>
          <v-autocomplete
              :menu-props="{ offsetY: true }"
              outlined
              v-model="selectedItem"
              :items="items"
              :loading="isLoading"
              :search-input.sync="search"
              item-text="text"
              item-value="value"
              label="Císlo várky"
              clearable
          />
          <span class="vDataTable__btnsWrapper vDataTable__btnsWrapper--field my-4">
           <v-chip>
              Celkem:{{ this.batch.weight - weightSub }}g / {{ this.batch.weight }}g
            </v-chip>
          </span>

          <div class="vDataTable__btnsWrapper vDataTable__btnsWrapper--field my-4">
            <v-text-field
                v-model="weightSub"
                label="Váha "
                :default="0"
                step="0.01"
                suffix="g"
                outlined
                type="number"
                :disabled="true"
            />
          </div>

          <div class="vDataTable__btnsWrapper vDataTable__btnsWrapper--field my-4" v-if="batchItems.length">
            <v-data-table
                v-model="selectedBatchItems"
                :headers="headers"
                :items="batchItems"
                class="elevation-0"
                :hide-default-footer="true"
                disable-pagination
                :show-select="true"
            >

            </v-data-table>
          </div>


        </v-card-text>
        <v-card-actions class="d-flex flex-wrap mt-4">
          <v-btn class="aBtn--primaryInv flex-grow-1" @click="dialog=false">Ne</v-btn>
          <v-btn class="primary flex-grow-1" @click="confirmAction" :loading="saving">Ano</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

import rules from "@/utils/rules";
import {fetchBatchList, moveBatch} from "@/components/batch/api";
import {getSerialPortData, isSerialPortApiAvailable} from "@/utils/serialport";
import {ACTION_TYPES as AT} from "@/store/action-types";

export default {
  name: 'DialogBatchReviewMove',
  computed: {
    rules() {
      return rules
    },
    selectedIds() {
      return [this.batch.id]
    },
  },
  props: {
    batch: {
      required: false, type: Object, default: () => {
      },
    },
    batchItems: {
      required: false, type: Array, default: () => {
        return []
      }
    },
    msg: {required: false, type: String, default: ''}
  },
  watch: {
    search: {
      handler() {
        if (!this.selectedItem) {
          this.fetchData()
        }
      },
    },
    dialog: {
      handler() {
        this.selectedBatchItems = this.batchItems
      }
    },
    selectedBatchItems: {
      handler() {
        this.weightSub = this.selectedWeight()
      },
      deep: true
    }
  },
  data() {
    return {
      saving: false,
      selectedBatchItems: [],
      search: "",
      weightSub: this.batchItems.length ? this.selectedWeight() : this.batch.weight,
      isLoading: false,
      selectedItem: null,
      items: [],
      dialog: false,
      delayTimer: 500,
      headers: [
        {text: 'Typ', align: 'start', value: 'type_jewel.description'},
        {text: 'Celková hmotnost (g)', value: 'weight'},
        {text: '', value: 'action', sortable: false}
      ],
    }
  },
  methods: {
    getSerialPortData,
    isSerialPortApiAvailable,
    fetchData() {
      fetchBatchList({search: this.search, deleted: false, pagination: false}).then(response => {
        this.items = response.data.results.filter(i => !this.selectedIds.includes(i.id) && i.commodity_purity?.purity === this.batch.commodity_purity?.purity).map(i => {
          return {
            value: i.id,
            text: `${i.identifier} | ${i.type_batch?.description || ""} - ${i.type_commodity?.description || ""} - ${i.commodity_purity?.purity || ""}`,
          }
        })
      })
    },
    reset() {
      this.search = ""
      this.selectedItem = null
    },
    async confirmAction() {
      this.saving = true
      await moveBatch(this.selectedItem, [{
        ...this.batch,
        weightSub: this.weightSub,
        batchItems: this.selectedBatchItems.map(i => i.id)
      }])
      this.$emit("update")
      this.$store.dispatch(AT.displayMessage, {
        errorMessage: 'Várka byla úspěšně přesunuta',
        many: false,
        isStringMessage: true
      })
      this.dialog = false
      this.saving = false
      this.reset()
    },
    selectedWeight() {
      let sum = 0;
      this.selectedBatchItems?.forEach(i => {
        sum += i.weight;
      });
      return Number(sum)
    },
  }

}

</script>